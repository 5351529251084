import { AfterViewChecked, Component, NgZone, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AbstractComponent } from '@helpers';
import { internalAnalyticsHelper } from './shared/heplers/internal-analytics.helper';

@Component({
  selector: 'apo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractComponent implements OnInit, AfterViewChecked {
  lazyModuleIsLoading: boolean;
  timerHandler: number;

  constructor(
    private router: Router,
    private zone: NgZone,
  ) {
    super();
  }

  ngOnInit() {
    this.checkLazyLoading();
  }

  private checkLazyLoading() {
    this.router.events.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.lazyModuleIsLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.lazyModuleIsLoading = false;
      }
    });
  }

  ngAfterViewChecked() {
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }

    this.zone.runOutsideAngular(() => {
      this.timerHandler = setTimeout(() => {
        internalAnalyticsHelper('pageViewUpdated');
        console.log('View updated');
      }, 500)
    })
  }
}
